import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Assets
import { ReactComponent as MapIcon } from "../../assets/svgs/map.svg";
import { ReactComponent as ArrowIcon } from "../../assets/svgs/arrowDropdown.svg";
import EuaIcon from "../../assets/svgs/eua.svg";
import SpainIcon from "../../assets/svgs/spain.svg";
import BrazilIcon from "../../assets/svgs/brazil.svg";

// Utils
import { isEs, isEn, isPtBr } from "../../i18n";

// Styles
import { WrapperDropdown } from "./styles";

const Dropdown = () => {
  const { i18n, t } = useTranslation();
  const selectedLanguage = i18n.language;

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handler = () => setShowMenu(false);
    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    // console.log("DropDown Locale", selectedLanguage);
    try {
      if (selectedLanguage && selectedLanguage.length > 1) {
        if (selectedLanguage[0] === "e" && selectedLanguage[1] === "s") {
          i18n.changeLanguage("es");
          return;
        }
        if (selectedLanguage[0] === "e" && selectedLanguage[1] === "n") {
          i18n.changeLanguage("en-US");
          return;
        }
        if (selectedLanguage !== "pt-BR") {
          i18n.changeLanguage("en-US");
        }
      }
    } catch (error) {
      //
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (t) {
      document.title = t("Page.title");
    }
  }, [selectedLanguage, t]);

  const handleInputClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleChangeLanguage = (language) => {
    // Trocando o idioma na chamada da função
    i18n.changeLanguage(language);
  };

  return (
    <WrapperDropdown>
      <div
        className="dropdown-locale-input noselect"
        onClick={handleInputClick}
      >
        <MapIcon />
        <div className="d-none d-xl-block">Language</div>
        <ArrowIcon className="dropdown-locale-arrow" />
      </div>
      <div
        className={`dropdown-locale-menu noselect ${
          showMenu ? "d-block" : "d-none"
        }`}
      >
        <div
          className={`dropdown-locale-item ${
            isEn(selectedLanguage) ? "dropdown-locale-item-selected" : ""
          }`}
          onClick={() => handleChangeLanguage("en-US")}
        >
          <img src={EuaIcon} alt="en" />
          <span className="d-none d-xl-block">English</span>
        </div>
        <div
          className={`dropdown-locale-item ${
            isEs(selectedLanguage) ? "dropdown-locale-item-selected" : ""
          }`}
          onClick={() => handleChangeLanguage("es")}
        >
          <img src={SpainIcon} alt="es" />
          <span className="d-none d-xl-block">Español</span>
        </div>
        <div
          className={`dropdown-locale-item ${
            isPtBr(selectedLanguage) ? "dropdown-locale-item-selected" : ""
          }`}
          onClick={() => handleChangeLanguage("pt-BR")}
        >
          <img src={BrazilIcon} alt="pt-BR" />
          <span className="d-none d-xl-block">Português</span>
        </div>
      </div>
    </WrapperDropdown>
  );
};

export default Dropdown;
